import $ from 'jquery';
import whatInput from 'what-input';

window.$ = $;

import Foundation from 'foundation-sites';
//require('slick-carousel-latest');
//import Swiper from 'swiper/bundle';
//import 'swiper/css/bundle';
// If you want to pick and choose which modules to include, comment out the above import Foundation and uncomment the line below
//import './lib/foundation-explicit-pieces';

import './responsive-tables';
import './bizazz';
import './site';

//const swiper = new Swiper('.swiper',
//{
//	direction: 'vertical',
//	loop: true;
//	pagination:
//	{
//		el: '.swiper-pagination',
//	},
//	navigation:
//	{
//		nextEl: '.swiper-button-next',
//		prevEl: '.swiper-button-prev',
//	},
//	scrollbar:
//	{
//		el: 'swiper-scrollbar',
//	}
//});

//$('.largeslick').slick({
//	slidesToShow: 1,
//	//slidesToScroll: 1,
//	dots: true,
//	infinite: true,
//	autoplaySpeed: 6000,
//	autoplay: true,
//	speed: 1000,
//	arrows: true,
//	fade: true,
//	cssEase: 'linear'
//});

//$('.smallslick').slick({
// 	centerMode: true,
//	centerPadding: '60px',
//	slidesToShow: 3,
//	//slidesToScroll: 1,
//	//dots: true,
//	infinite: true,
//	autoplaySpeed: 3000,
//	autoplay: true,
//	//arrows: true,
//  responsive: [
//    {
//      breakpoint: 768,
//      settings: {
//        arrows: false,
//        centerMode: true,
//        centerPadding: '40px',
//        slidesToShow: 3
//      }
//    },
//    {
//      breakpoint: 480,
//      settings: {
//        arrows: false,
//        centerMode: true,
//        centerPadding: '40px',
//        slidesToShow: 1
//      }
//    }
//  ]
//});

$(document).foundation();
